import './_vendor';
import './_components';

function generateSessionId() {
  const currentDate = new Date().toISOString().replace(/[-:T.Z]/g, '');
  const randomPart = Math.floor(100000 + Math.random() * 900000).toString();
  return `${currentDate}${randomPart}`;
}

$(window).on('load', () => {
  let sessionId = localStorage.getItem('sessionid');

  if (!sessionId) {
    sessionId = generateSessionId();
    localStorage.setItem('sessionid', sessionId);
  }
  
  $('.page__body').attr('data-current-user-id', sessionId);

  setTimeout(() => {
    $('.section-dialog').css({ opacity: 1 });
  }, 150);
});
